import React from 'react';
import {graphql, Link} from 'gatsby';
import {Avatar, Button, Divider, Typography} from 'antd';
import {renderRichText} from 'gatsby-source-contentful/rich-text';
import {documentToPlainTextString} from '@contentful/rich-text-plain-text-renderer';
import Layout from '../../components/layout';
import Helmet from 'react-helmet';
import {useIntl} from 'gatsby-plugin-intl';
import styles from './vacancyTemplate.module.less';
import richTextRendererOptions from '../richTextRendererOptions';
import VisibilitySensor from '../../components/VisibilitySensor';
import ApplyJobBlock from '../../components/apply-job-block/ApplyJobBlock';
import JobsFAQBlock from '../../components/jobs-faq-block/JobsFAQBlock';
import JobsFAQBlockMobile from '../../components/jobs-faq-block-mobile/JobsFAQBlockMobile';
import VacanciesTeaser from '../../components/vacancies-teaser/VacanciesTeaser';

export default function Template({data}) {
    const {locale, formatMessage} = useIntl();
    const {Title, Text} = Typography;
    const {title, employmentType, subtitle, description, category} = data.contentfulVacancy;
    const jobs = data.allContentfulVacancy.edges
        .filter(({node: job}) => job.slug !== data.contentfulVacancy.slug)
        .map(({node: job}) => job);

    return (
        <Layout data={data}>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={`${documentToPlainTextString(JSON.parse(description.raw)).slice(0, 250)}...`} />
            </Helmet>
            <div className={styles.substrate} style={{backgroundColor: category.color}}>
                <div className={styles.wrapper}>
                    <div className={styles.categoryTitle}>
                        <div className={styles.avatarGroup} style={{backgroundColor: category.color}}>
                            <Avatar
                                size={80}
                                src={category.icon.file.url}
                                className={styles.avatar}
                            />
                            <Text className={styles.subtitle}>
                                <div className="text-bold">{title}</div>
                                <div className="text-semi-bold">{employmentType}</div>
                            </Text>
                        </div>
                        <div className={styles.imageWrapper}>
                            <img
                                src={category.image.fixed.src}
                                alt={category.title}
                                className={styles.image}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.section}>
                <Title level={1}>{title} {employmentType && `(${employmentType})`}</Title>
                <div className={styles.description}>{subtitle}</div>
                <Link to={`/${locale}/contact`} state={{subject: formatMessage({id: 'contact-form.subject.application'})}}>
                    <Button type="ghost">
                        {formatMessage({id: 'apply-job.button'})}
                    </Button>
                </Link>
                <div className="text-left">{description && renderRichText(description, richTextRendererOptions)}</div>
            </div>
            <div className={styles.applyBlock}>
                <ApplyJobBlock />
            </div>
            <VisibilitySensor>
                <VacanciesTeaser jobs={jobs} />
            </VisibilitySensor>
            <VisibilitySensor>
                <Divider className={styles.divider} />
                <div className="show-lg-down">
                    <JobsFAQBlockMobile />
                </div>
                <div className="show-lg-up">
                    <JobsFAQBlock />
                </div>
            </VisibilitySensor>
        </Layout>
    );
};

export const pageQuery = graphql`
  query($slug: String!, $locale: String) {
    contentfulVacancy(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      subtitle
      slug
      employmentType
      description { raw }
      category { 
        title
        image {  
          fixed(width: 600, toFormat: PNG) {
            src
          }
        }
        icon { file { url } } 
        color 
      }
    }
    allContentfulVacancy(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          id
          title
          slug
          employmentType
          category { 
            color 
          }
        }
      }
    }
    allContentfulArticle(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          title
          slug
          excerpt
          category {
            color
            slug
          }
        }
      }
    }
     allContentfulStory(
      sort: {fields: [category___createdAt, createdAt], order: [ASC, DESC]}, 
      filter: {node_locale: {eq: $locale}, slug: {ne: "skip"}}
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;
